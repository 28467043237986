//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'AuthDescription'
}
