//
//
//
//
//
//
//
//
//

import AuthContainer from '@/components/auth/AuthContainer'
import LoginForm from '@/components/auth/LoginForm'
import Pageview from '@/mixins/Pageview'

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'LoginPage',
  components: {
    AuthContainer,
    LoginForm
  },
  mixins: [Pageview],
  layout: 'auth',
  auth: false
}
