export default {
  methods: {
    handleCaptchaVerified ({ token }) {
      this.form = {
        ...this.form,
        token
      }
    }
  }
}
