//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'ValidationMessages',
  props: {
    title: {
      type: String,
      required: true
    },
    validation: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  computed: {
    visible () {
      return this.validation && this.validation.$dirty && this.validation.$error
    },

    messages () {
      const errors = []

      if (this.validation.$params) {
        Object.keys(this.validation.$params)
          .forEach((p) => {
            if (!this.validation[p]) {
              errors.push(p)
            }
          })
      }

      return errors
    }
  },
  methods: {
    errorVisible (type) {
      return this.messages.includes(type)
    }
  }
}
