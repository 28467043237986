//
//
//
//
//
//
//

import VueHcaptcha from '@hcaptcha/vue-hcaptcha'
import ValidationMessages from '@/components/form/ValidationMessages'

export default {
  name: 'HCaptchaInput',
  components: {
    ValidationMessages,
    VueHcaptcha
  },
  props: {
    validation: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      hidden: false
    }
  },
  computed: {
    enabled () {
      return process.env.captchaEnabled === 'true'
    },
    siteKey () {
      return process.env.hCaptcha
    },
    theme () {
      return this.$store.state.ui.isDarkThemeEnabled ? 'dark' : 'light'
    }
  },
  watch: {
    theme (n, o) {
      if (n && n !== o) {
        setTimeout(() => {
          this.hidden = true

          setTimeout(() => {
            this.hidden = false
          }, 1)
        }, 1)
      }
    }
  },
  methods: {
    handleVerified (token, eKey) {
      this.$emit('verified', {
        token,
        eKey
      })
    }
  }
}
