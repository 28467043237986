//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { required, email } from 'vuelidate/lib/validators'
import AxiosErrorHandler from '@/mixins/AxiosErrorHandler'
import redirectMap from '@/lib/redirectMap'
import HCaptchaInput from '@/components/form/HCaptchaInput'
import ValidationMessages from '@/components/form/ValidationMessages'
import Captcha from '@/mixins/Captcha'

const initialForm = {
  email: null,
  password: null,
  token: null
}

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'LoginForm',
  components: {
    HCaptchaInput,
    ValidationMessages
  },
  mixins: [AxiosErrorHandler, Captcha],
  data () {
    return {
      form: { ...initialForm },
      sending: false
    }
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required
      }
    }
  },
  computed: {
    buttonMessage () {
      return this.sending ? this.$t('auth.login.button_label_sending') : this.$t('auth.login.button_label')
    }
  },
  methods: {
    async login () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.sending = true

        try {
          const { data } = await this.$auth.loginWith('local', { data: this.form })
          const { user } = data.data
          this.$auth.setUser(user)

          const activeRole = this.$store.getters['user/getActiveRoleType']
          const activeRoleData = this.$store.getters['user/getActiveRoleData']
          const roleName = this.$store.getters['user/getActiveRoleTypeName']
          const profileCompleted = this.$store.getters['user/isUserCompleted']
          const openStepRoute = this.$store.getters['user/currentWizardStep']

          this.$smartContract.createKeypairs(this.form)
          this.$smartContract.createBcData()
            .then(async () => {
              this.$store.commit('user/activateBcData')
              const { locale } = this.$store.state.i18n
              let resBc
              if (activeRole.name === 'freelancer' &&
                activeRoleData?.name && activeRoleData?.bcId == null) {
                const bcFreelancer = await this.$smartContract
                  .setFreelancerProperties(activeRoleData, locale)
                resBc = await this.$axios.put('/freelancers/regBcFreelancer', bcFreelancer)
                console.log(bcFreelancer)
                console.log(resBc.data)
              } else if (activeRole.name === 'client' &&
                activeRoleData?.name && activeRoleData?.bcId == null) {
                const bcClient = await this.$smartContract
                  .setClientProperties(activeRoleData, locale)
                resBc = await this.$axios.put('/clients/regBcClient', bcClient)
                console.log(bcClient)
                console.log(resBc.data)
              }
            })
            .catch(err => console.error(err.response ? err.response.data : err))

          this.form = { ...initialForm }
          this.$v.$reset()

          if (this.$Tawk) {
            this.$Tawk.$updateChatUser({
              name: this.$store.getters['user/getUserName'],
              email: user.email
            })
          }

          if (this.$sentry) {
            this.$sentry.setUser({
              id: user.id,
              email: user.email
            })
          }

          this.$gtag.set({
            user_id: user.id
          })

          this.$gtag.event('login', {
            role: roleName,
            method: 'email'
          })

          if (!profileCompleted) {
            await this.$router.replace(this.localePath(`/create-${activeRole.name}/${openStepRoute}`))
          } else {
            const redirect = this.$auth.$storage.getUniversal('redirect')

            if (redirect) {
              await this.$router.replace(redirect)
            } else {
              await this.$router.replace(this.localePath(redirectMap[roleName]))
            }
          }

          // reset socket connection
          this.$socket.disconnect()
          this.$socket.connect()

          this.sending = false
        } catch (err) {
          this.sending = false
          await this.handleError(err)
        }
      }
    }
  }
}
